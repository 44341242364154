<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>고객사 관리</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <div class="pageInfo-area">
                    <div class="page-info">
                        <img src="@/assets/img/img-pageInfo1.png" alt="img" class="pi-img">
                        <div class="pi-tit">이 페이지는 보안페이지입니다.</div>
                        <div class="pi-txt txt1">
                            복사 및 외부유출이 금지되어 있어<br>
                            현재IP에서는 열람이 불가능 합니다.
                        </div>
                        <div class="pi-txt txt2">(문의 : 솔루션 개발팀)</div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src


export default {
}
</script>

<style lang="scss" scoped>
</style>